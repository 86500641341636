



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { IDisposable, isObservable } from '@/misc/MySubject';
import { DialogService, IButtonModel, IDialogModel } from '@/services/DialogService';

@Component
export default class DialogContainer extends Vue
{
  sub: IDisposable;
  m: IDialogModel = null;

  body: string;
  disp: IDisposable;

  created()
  {
    window.addEventListener("keydown", this.onKeydown);

    this.sub = DialogService.launch.subscribe((m) => {
      if(this.disp)
        this.disp.dispose();

      this.m = m;
      if(isObservable(m.body))
      {
        this.disp = m.body.subscribe(e => {
          this.body = e;
        });
      }
      else
      {
        this.body = m.body;
      }
    });
  }

  onKeydown(ev: KeyboardEvent) {
    if(ev.key == "Escape" && this.m != null)
      this.close();
  }

  close()
  {
    this.m = null;
    if(this.disp != null)
      this.disp.dispose();
  }

  destroyed()
  {
    if(this.sub)
      this.sub.dispose();
    window.removeEventListener("keydown", this.onKeydown)
  }

  onClick(b: IButtonModel)
  {
    if(typeof b.click === "function")
      b.click();
    this.close();
  }

  onDecline()
  {
  }
}
