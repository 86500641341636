import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../pages/Login.vue';
import Barcode from '../pages/Barcode.vue';
import MangelListe from "../pages/MangelListe.vue";
import MangelListeHelp from '../pages/MangelListeHelp.vue';
import Help from '../pages/Help.vue';
import Datenschutz from '../pages/Datenschutz.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Home',
    component: Login
  },
  {
    path: '/hilfe',
    name: 'Hilfe',
    component: Help
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  },
  {
    path: '/mangelliste/hilfe',
    name: 'Mangelverwaltung Hilfeseite',
    component: MangelListeHelp
  },
  {
    path: '/mangelliste/:id',
    name: 'Mangelliste',
    component: MangelListe
  },
  {
    path: '/:code',
    name: 'Home',
    component: Barcode
  },
  {
    path: '/',
    name: 'Home',
    component: Barcode
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
