import { MySubject } from '../misc/MySubject';
export interface ILightboxModel
{
  imageSrc: string;
}

class LightboxServiceClass
{
  private _showLightbox = new MySubject<ILightboxModel>();
  $showLightbox = this._showLightbox.asObservable();

  showLightbox(model: ILightboxModel)
  {
    this._showLightbox.emit(model);
  }
}

export const LightboxService = new LightboxServiceClass();
