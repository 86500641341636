





import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ElementView extends Vue
{
  @Prop()
  element: HTMLElement;

  init = false;

  @Watch("element")
  onElementChanged()
  {
    let container = this.$refs.container as Element;
    if(this.init == true)
    {
      while(container.firstChild)
      {
        container.removeChild(container.firstChild);
      }
      container.appendChild(this.element);
    }
    else
    {
      this.init = true
      container.appendChild(this.element);
    }
  }

  mounted()
  {
    if(this.init == false)
    {
      this.onElementChanged();
    }
  }
}
