








import { Component, Prop, Vue } from 'vue-property-decorator'
import { InfoService, MyMessage } from '@/services/InfoService';
import { IDisposable } from '@/misc/MySubject';
import Toast from './Toast.vue';

@Component({
  components: {
    Toast
  }
})
export default class ToastContainer extends Vue
{
  sub: IDisposable;
  @Prop()
  messageKey: string;

  get key() {
    return this.messageKey ?? "global";
  }

  messages: MyMessage[] = [];

  created()
  {
    this.sub = InfoService.subscribe(this.key, (m) => {
      this.messages = m;
    });
  }

  destroyed()
  {
    if(this.sub)
      this.sub.dispose();
  }

  onCloseClicked(m: MyMessage)
  {
    InfoService.removeMessage(m.counter);
  }
}
