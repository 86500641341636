import { MySubject, IObservable, IDisposable } from '../misc/MySubject';
export interface IMessage {
  severity?: Severity;
  title?: string;
  subtitle?: string;
  body?: string | MySubject<string>;
  id?: any;
  key?: string;
  tags?: string | string[];
  life?: number;
  sticky?: boolean;
  closable?: boolean;
  data?: any;
  onClick?: () => any;
  onDblClick?: () => any;
}

export class MyMessage implements IMessage
{
  private static msgCnt = 0;
  public counter: number;
  severity?: Severity;
  title?: string;
  subtitle?: string;
  body?: string|MySubject<string>;
  id?: any;
  key?: string;
  life?: number;
  sticky?: boolean;
  closable?: boolean;
  data?: any;
  onClick?: () => any;
  onDblClick?: () => any;
  tags: string[];

  constructor(msg: IMessage, timeout: number = null)
  {
    this.counter = MyMessage.msgCnt;
    MyMessage.msgCnt ++;
    Object.assign(this, msg);
    this.key = this.key || "global";

    this.life = timeout || this.life;

    if(this.sticky == null && this.life == null)
      this.sticky = true;
    if(this.closable == null)
      this.closable = true;

    if(msg.tags != null && !Array.isArray(msg.tags))
      this.tags = [ msg.tags ];
    else
      this.tags = (msg.tags as string[]) ?? [];
  }
}

export type Severity = "busy"|"success"|"info"|"warn"|"error";


class InfoServiceClass
{
  private messages: MyMessage[] = [];

  private _msg = new MySubject<MyMessage[]>();

  public subscribe(key: string, fn: (messages: MyMessage[]) => any): IDisposable
  {
    let sub = this._msg.subscribe(e => {
      if(e)
      {
        fn(e.filter(m => m.key == key));
      }
    })
    return sub;
  }

  private updateMessages()
  {
    this._msg.emit(this.messages);
  }

  public addMessage(msg: IMessage, timeout: number = null, selfHandleTimeout: boolean = false): number
  {
    let m = new MyMessage(msg, timeout);

    this.messages.push(m);
    this.updateMessages();

    if(selfHandleTimeout != true && timeout != null)
      setTimeout(() => this.removeMessage(m.counter), timeout);

    return m.counter;
  }

  public show(severity: Severity, summary: string, detail: string, tags: string|string[] = null, timeout: number = null, selfHandleTimeout: boolean = false): number
  {
    return this.addMessage({
      severity,
      title: summary,
      body: detail,
      tags: tags
    }, timeout, selfHandleTimeout);
  }

  public removeMessage(counter: number)
  {
    if(counter == null)
      return;
    this.messages.remove(m => m.counter == counter);
    this.updateMessages();
  }

  public removeMessagesWithTag(tag: string)
  {
    this.messages.remove(m => m.tags.contains(tag));
    this.updateMessages();
  }

  public clearMessages(key: string = "global")
  {
    if(key != null)
      this.messages = this.messages.filter(m => m.key != key);
    else
      this.messages = [];

    this.updateMessages();
  }

  public hasMessage(key: string = "global"): boolean
  {
    return this.messages.any(m => m.key == key);
  }
}

export const InfoService = new InfoServiceClass();
