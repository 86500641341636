







import { IDisposable } from '@/misc/MySubject'
import { EventService } from '@/services/EventService'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingBar extends Vue
{
  subs: IDisposable[] = [];
  toggled = false;

  lastCounter: number = 0;

  created()
  {
    EventService
      .subscribe("requestStarted", (ev) => {
        this.toggled = true;
        this.lastCounter = ev.counter;
      })
      .addTo(this.subs)

    EventService
      .subscribe("requestEnded", (ev) => {
        if(this.lastCounter == ev.counter)
        {
          this.lastCounter = null;
          this.toggled = false;
        }
      })
      .addTo(this.subs)


  }
}
