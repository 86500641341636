import { ApiService } from './ApiService';
import { MyBehaviorSubject } from '../misc/MySubject';
import { deepClone } from '@/common';
import { CookieService } from './CookieService';

export interface INavbarElementModelBase
{
  class?: string;
  id?: string;
  visible?: (isMobile: boolean) => boolean;
}
export interface INavbarNavigation extends INavbarElementModelBase
{
  kind: "navigation";
  icon?: string;
  href?: string;
  action?: () => any
  label?: string;
  target?: string;
}

export interface INavbarButton extends INavbarElementModelBase
{
  kind: "button";
  icon: string;
  label: string;
  action: () => any;
}

export interface INavbarImage extends INavbarElementModelBase
{
  kind: "image";
  logo?: string;
  title?: string;
}

export type NavbarElementModel = INavbarNavigation
  | INavbarButton
  | INavbarImage


export interface INavbarSectionModel
{
  class?: string;
  elements?: NavbarElementModel[];
}

export interface INavbarModel
{
  brand?: INavbarSectionModel;
  start?: INavbarSectionModel;
  end?: INavbarSectionModel;
  class?: string;
}

const defaultModel: INavbarModel = {
  brand: {
    elements: [
      {
        kind: "image",
        logo: "",
        title: "Flex Dokucenter"
      }
    ]
  } ,
  end: {
    elements: [
      {
        kind: "navigation",
        label: "Hilfe",
        href: "/hilfe",
        icon: "question-circle",
        id: "help",
        target: "_blank"
      },
      {
        kind: "navigation",
        label: "Anleitung",
        href: "https://dokucenterfiles.blob.core.windows.net/static/Anleitung_Portal_Maengelverwaltung.pdf",
        icon: "info-circle",
        id: "anleitung",
        target: "_blank"
      },
      {
        kind: "navigation",
        label: "Logout",
        icon: "sign-out-alt",
        action: () => ApiService.logout(),
        visible: () => CookieService.tokenExpiration > new Date()
      },
      {
        kind: "image",
        class: "flex-logo",
        logo: "/img/flex.png"
      }
    ]
  }
}

class NavbarServiceClass
{
  get defaultModel(): INavbarModel {
    return deepClone(defaultModel);
  }
  model: INavbarModel = this.defaultModel;
  _modelSub = new MyBehaviorSubject<INavbarModel>(this.model)
  $model = this._modelSub.asObservable()

  setModel(model: INavbarModel)
  {
    this.model = model;
    this._modelSub.emit(this.model);
  }

  resetModel()
  {
    this.setModel(this.defaultModel);
  }
}

export const NavbarService = new NavbarServiceClass();
