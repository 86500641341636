import { Optional } from "@/types/helpers";

export interface ICookies
{
  tokenExpiration: string;
  firma: string;
  email: string;
}

function parseCookies()
{
  return document.cookie
    .split(';')
    .reduce((res, c) => {
      const [key, val] = c.trim().split('=').map(decodeURIComponent)
      try {
        return Object.assign(res, { [key]: JSON.parse(val) })
      } catch (e) {
        return Object.assign(res, { [key]: val })
      }
    }, {}) as ICookies;
}

class CookieServiceClass
{
  cookies: ICookies;

  get tokenExpiration(): Date
  {
    if(String.isNullOrEmpty(this.cookies.tokenExpiration))
      return new Date(0);
    let d = new Date(this.cookies.tokenExpiration);
    if(isNaN(d.getTime()))
      return new Date(0);
    return d;
  }

  constructor()
  {
    this.cookies = parseCookies();
  }

  setCookies(o: Optional<ICookies>)
  {
    for(let prop in o)
    {
      this.setCookie(prop as any, o[prop], false)
    }
    // this._cookiesChanged.emit(this.getCookieObject());
  }

  setCookie(name: keyof ICookies, value: string, emit: boolean = true)
  {
    this.cookies[name] = value as any;
    document.cookie = `${name}=${value};max-age=${60*60*24*365}`;
    console.log(this.tokenExpiration)
  }

}

export const CookieService = new CookieServiceClass();
