






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MyFooter extends Vue
{
  @Prop()
  private msg!: string;
}
