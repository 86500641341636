import { IMangelDTO } from './types/api';

export interface IPropertyViewDefinition<TKey>
{
  key: TKey;
  order?: number;
  displayName: string;
  filter?: "distinct"|"text";
  isMobileMainCol?: boolean;
  isMobileOnly?: boolean;
  class?: string;
  mobileFilter?: boolean;
}

export const MAX_PHOTOS = 5;

export type IMangelRow = IMangelDTO & { kurzbeschreibung: string }

export const MangelListColumns: IPropertyViewDefinition<keyof IMangelRow>[] = [
  {
    key: "mangelId",
    displayName: "Lfd. Nr.",
    isMobileMainCol: true
  },
  {
    key: "kurzbeschreibung",
    displayName: "Beschreibung",
    isMobileMainCol: true,
    isMobileOnly: true
  },
  {
    key: "art",
    displayName: "Art",
    isMobileMainCol: true,
    filter: "distinct",
    mobileFilter: true
  },
  {
    key: "bereich",
    displayName: "Bereich",
    filter: "distinct",
    mobileFilter: true
  },
  {
    key: "prio",
    displayName: "Prio",
    filter: "distinct",
    isMobileMainCol: true
  },
  {
    key: "beschreibung",
    displayName: "Beschreibung des Mangels",
    class: "text-container"
  },
  {
    key: "anmerkungLidl",
    displayName: "Anmerkung zum Mangel",
    class: "text-container"
  },
]

MangelListColumns.forEach(c => c.class = c.class ?? "")
