
























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { InfoService, MyMessage } from '@/services/InfoService';
import { IDisposable, isObservable } from '@/misc/MySubject';

@Component
export default class Toast extends Vue
{
  @Prop()
  model: MyMessage;
  body: string = "";

  subs: IDisposable[] = []

  @Watch("model")
  onModelChanged(old, n)
  {
    console.log("watch");
    this.subs.forEach(s => s.dispose());
    this.subs = [];
    this.updateModel();
  }

  updateModel()
  {
    if(isObservable(this.model.body))
    {
      this.model.body
        .subscribe(e => {
          console.log("update body");
          this.body = e;
        })
        .addTo(this.subs);
    }
    else
    {
      this.body = this.model.body;
    }
  }

  created()
  {
    this.updateModel();
  }

  destroyed()
  {
    this.subs.forEach(e => e.dispose());
  }

  @Emit("close")
  emitCloseClicked()
  {

  }

  onCloseClicked()
  {
    this.emitCloseClicked();
  }
}
