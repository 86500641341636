import { MySubject, IObservable, IDisposable } from '../misc/MySubject';
import { Severity } from './InfoService';


export interface IDialogModel
{
  mode: "confirm";
  footer?: string;
  header: string;
  body: string|IObservable<string>;
  icon?: string;
  buttons: IButtonModel[]
}

type Action = () => void | Promise<void>;

export interface IButtonModel
{
  icon?: string;
  label: string;
  click?: Action
}

export interface IDialogResult
{
  result: "Yes"|"No"
}

class DialogServiceClass
{
  private _sub = new MySubject<IDialogModel>();
  launch = this._sub.asObservable();

  public confirm(model: IDialogModel)
  {
    this._sub.emit(model);
  }
}

export const DialogService = new DialogServiceClass();
