import { debounced } from "@/common";
import { MySubject } from "@/misc/MySubject";

const breakpoint = 768;

class ResizeServiceClass
{
  sub = new MySubject<any>();

  isMobileView = false;

  constructor()
  {
    window.addEventListener("resize", this.onResize);
    this.isMobileView = window.innerWidth < breakpoint;
  }

  onResize = debounced(() => {
    this.isMobileView = window.innerWidth < breakpoint;
    this.sub.emit(null);
  }, 100)
}

export const ResizeService = new ResizeServiceClass();
