import { render, staticRenderFns } from "./MangelStatusIcon.vue?vue&type=template&id=3ffb19eb&scoped=true&"
import script from "./MangelStatusIcon.vue?vue&type=script&lang=ts&"
export * from "./MangelStatusIcon.vue?vue&type=script&lang=ts&"
import style0 from "./MangelStatusIcon.vue?vue&type=style&index=0&id=3ffb19eb&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffb19eb",
  null
  
)

export default component.exports