export enum MangelStatus
{
  Undefined = "Undefined",
  Behoben = "Behoben",
  NichtBehoben = "Nicht behoben",
  NichtBeauftragt = "Nicht beauftragt",
  KeinMangel = "Kein Mangel",
}

export interface IMangelDTO
{
  id: number;
  mangelId: string;
  locationId: string;

  //Eingabe
  kommentar: string;
  mangelStatus: MangelStatus;
  abgeschlossenAt: string;

  //Anzeigefelder
  bereich: string;
  art: string;
  beschreibung: string;
  prio: string;
  anmerkungLidl: string;
  bilder: IMangelBild[];
  mangelBilder: IMangelBild[];

  //durchschleusen
  einsatzId: any;
  reportId: any;
  abgeschlossenFirma: any;
  createdAt: any;
  modifiedAt: any;
}

export interface IMangelListeSaveRequest
{
  mode: "Save"|"Commit",
  payload: IMangelListeResponse;
}

export interface IMangelListeSaveResponse
{
  newModel: IMangelListeResponse
}

export interface IMangelListeResponse
{
  maengel: IMangelDTO[];
  location: string;
}

export interface IUploadImageModel
{
  filename?: string;
  mangelId: number;
  reportId: string;
}

export interface IUploadImageResponse
{
  id: string;
}

export interface IMangelBild
{
  id: string;
  bezeichnung: string;
  url: string;
}

export interface ILoginModel
{
  email: string;
  firma: string;
  requestedResource: string;
  password: string;
}

export interface IRequestPasswordModel
{
  email: string;
  firma: string;
  requestedResource: string;
}

export interface IRequestPasswordResponse
{
}

export interface ILoginResponse
{
  tokenExpiration: string;
  email: string;
  firma: string;
}
