


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { EventService } from '@/services/EventService';
import { MangelStatus } from '@/types/api';
import { INavbarModel, NavbarElementModel, NavbarService } from '@/services/NavbarService';
import { IDisposable } from '@/misc/MySubject';
import NavbarElement from "@/components/NavbarElement.vue";
import { ResizeService } from '@/services/ResizeService';
import { ApiService } from '@/services/ApiService';

@Component({
  components: {
    NavbarElement
  }
})
export default class Navbar extends Vue
{
  isActive = false;
  logoutAnchorRef;
  MangelStatus = MangelStatus;
  subs: IDisposable[] = [];

  model: INavbarModel = NavbarService.defaultModel;

  get brandElements(): NavbarElementModel[] {
    return this.model.brand.elements.filter(e => e.visible == null || e.visible(this.isMobile) == true)
  }
  get startElements(): NavbarElementModel[] {
    return this.model.start.elements.filter(e => e.visible == null || e.visible(this.isMobile) == true)
  }
  get endElements(): NavbarElementModel[] {
    return this.model.end.elements.filter(e => e.visible == null || e.visible(this.isMobile) == true)
  }

  isMobile = ResizeService.isMobileView;

  isTestSystem = ApiService.isTestSystem;

  created()
  {
    ResizeService.sub
      .subscribe(e => this.isMobile = ResizeService.isMobileView)
      .addTo(this.subs);

    NavbarService.$model
      .subscribe(m => {
        this.model = m;
      })
      .addTo(this.subs)
  }

  destroyed()
  {
    this.subs.forEach(s => s.dispose());
  }

  @Prop()
  private msg!: string;

  toggleHamburger()
  {
    this.isActive = !this.isActive;
  }

  onSaveClicked()
  {
    EventService.emit({
      kind: "saveClicked"
    });
  }
}
