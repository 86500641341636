



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { IDisposable, isObservable } from '@/misc/MySubject';
import { DialogService, IButtonModel, IDialogModel } from '@/services/DialogService';
import { ILightboxModel, LightboxService } from '@/services/LightboxService';

@Component
export default class LightboxContainer extends Vue
{
  subs: IDisposable[] = [];
  m: ILightboxModel = null;

  created()
  {
    window.addEventListener("keydown", this.onKeydown);

    LightboxService.$showLightbox
      .subscribe((m) => {
        this.m = m;
      })
      .addTo(this.subs);
  }

  destroyed()
  {
    this.subs.forEach(d => d.dispose());
    window.removeEventListener("keydown", this.onKeydown)
  }

  onKeydown(ev: KeyboardEvent) {
    if(ev.key == "Escape" && this.m != null)
      this.close();
  }

  close()
  {
    this.m = null;
  }
}
