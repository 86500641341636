import { MySubject } from '../misc/MySubject';

interface ISaveClickedEvent
{
  kind: "saveClicked"
}

interface IRequestStartedEvent
{
  kind: "requestStarted",
  counter: number
}

interface IRequestEndedEvent
{
  kind: "requestEnded",
  counter: number;
}

export type EventModel = ISaveClickedEvent
  | IRequestEndedEvent
  | IRequestStartedEvent


class EventServiceClass
{
  private _sub = new MySubject<EventModel>();

  public ev = this._sub.asObservable();

  emit(ev: EventModel)
  {
    this._sub.emit(ev);
  }

  subscribe<TKind extends EventModel["kind"]>(kind: TKind, fn: (e: EventModel & { kind: TKind }) => any)
  {
    let sub = this.ev.subscribe((e) => {
      if(e.kind == kind)
        fn(e as any);
    });
    return sub;
  }
}

export const EventService = new EventServiceClass();
