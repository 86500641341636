import { assertNever } from '../common';
export enum ApiErrorKind
{
  GenericInternalError    = "GenericInternalError",
  GenericDbError          = "GenericDbError",
  BadRequestBody          = "BadRequestBody",
  GenericNotAllowed       = "GenericNotAllowed",
  InconsistentDataVersion = "InconsistentDataVersion",
  InvalidUserCredentials  = "InvalidUserCredentials",
  DataRecordNotFound      = "DataRecordNotFound",

  NoServerConnection   = "NoServerConnection",
  GenericFrontendError = "GenericFrontendError",
  GenericNotFound      = "GenericNotFound"
}

export interface IApiError
{
  meta: Record<string, any>;
  reason: string;
  kind: ApiErrorKind;
}

export class ApiError implements IApiError
{
  meta: Record<string, any>;
  reason: string;
  kind: ApiErrorKind;

  constructor(data: IApiError)
  {
    Object.assign(this, data);
  }

  toDetailMessage(): string
  {
    switch(this.kind)
    {
      case ApiErrorKind.InconsistentDataVersion:
        return "Speichern nicht möglich. Eine neuere Version der Daten wurde bereits von einem anderen Benutzer gespeichert. Laden Sie diese Seite neu und versuchen Sie es erneut.";
      case ApiErrorKind.NoServerConnection:
        return "Es besteht keine Verbindung zum Server. Bitte prüfen Sie ihre Internetverbindung und versuchen Sie die Aktion später erneut.";
      case ApiErrorKind.DataRecordNotFound:
        return `Die angeforderten Daten konnten nicht gefunden werden.\n${this.getReason()}`;
      case ApiErrorKind.GenericInternalError:
        return `Auf dem Server ist ein Fehler aufgetreten. Falls der Fehler besteht, kontaktieren Sie bitte den Systemadministrator.\n${this.getReason()+this.getMeta()}`;
      case ApiErrorKind.GenericFrontendError:
        return `Ein Fehler ist aufgetreten. Falls der Fehler besteht, kontaktieren Sie bitte den Systemadministrator.\n${this.getReason()+this.getMeta()}`;
      case ApiErrorKind.GenericNotFound:
        return `Diese Seite konnte nicht gefunden werden. Bitte versuchen Sie es später erneut oder kontaktieren sie den Systemadministrator.`;
      case ApiErrorKind.GenericNotAllowed:
        return `Sie sind nicht berechtigt diese Aktion auszuführen.`;
      case ApiErrorKind.BadRequestBody:
        return `Die gesendeten Daten sind invalid. Die Aktion konnte nicht durchgeführt werden`;
      case ApiErrorKind.GenericDbError:
        return `Ein Datenbankfehler ist aufgetreten. Falls der Fehler besteht, kontaktieren Sie bitte den Systemadministrator.\n${this.getReason()}`;
      case ApiErrorKind.InvalidUserCredentials:
        return `Ihre Username oder ihr Passwort ist inkorrekt.`;
      default:
        assertNever(this.kind);
        return `Fehler: ${ApiErrorKind[this.kind]}${this.reason != null ? `\nGrund: ${this.reason}` : ""}${this.meta != null ? `\n: ${JSON.stringify(this.meta)}` : ""}.\nFalls der Fehler besteht Kontaktieren Sie ihren Administrator.`;
    }
  }

  getReason()
  {
    return this.reason != null ? `\nGrund: ${this.reason}` : "";
  }

  getMeta()
  {
    return this.meta != null ? `\nMeta: ${JSON.stringify(this.meta)}` : "";
  }
}
