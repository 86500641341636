











import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DistinctSelect extends Vue
{
  @Prop()
  private values: any[];
  @Prop()
  dir: string;

  value: any = null;

  get distinctArray(): any[] {
    return this.values != null ? this.values.distinct() : []
  }

  @Emit("changed")
  emitChanged(e: any)
  {

  }

  onChange(e: any)
  {
    this.emitChanged(this.value);
  }

}
