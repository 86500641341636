


















































import { ResizeService } from '@/services/ResizeService'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

const MAX_MP = 6e6;
const JPG_COMPRESSION_QUALITY = 0.85; //between 0 and 1, default = 0.92

function readFile(file): Promise<HTMLElement>
{
  return new Promise((resolve, reject) => {
    try
    {
      let reader = new FileReader();
      reader.onload = (ev) => {
        try
        {
          let img = document.createElement("img");
          img.src = ev.target.result as string;
          img.onload = () => {
            try
            {
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);

              let mp = img.width * img.height;

              let width = img.width;
              let height = img.height;

              if(mp > MAX_MP)
              {
                let factor = Math.sqrt(MAX_MP / (width * height))

                width = width * factor;
                height = height * factor;
              }
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          
              let img2 = document.createElement("img");
              img2.src = canvas.toDataURL("image/jpeg", JPG_COMPRESSION_QUALITY);
              resolve(img2);
            }
            catch(err)
            {
              reject(err);
            }
          }
        }
        catch (err)
        {
          reject(err)
        }
      }
      reader.readAsDataURL(file);
    }
    catch(err)
    {
      reject(err);
    }
  });
}

export interface IImageFile
{
  element: HTMLElement,
  filename: string,
  id?: any;
}

@Component
export default class FileUpload extends Vue
{
  @Prop({ default: false })
  disabled: boolean;

  active = false;
  get isMobile()  {
    return ResizeService.isMobileView;
  }

  @Emit("file")
  emitFile(e: IImageFile)
  {
  }

  onDropdownClick()
  {
    if(this.disabled !== true)
    {
      this.active = !this.active;
    }
  }

  onChange(ev)
  {
    let promises: Promise<any>[] = [];
    for(let f of ev.target.files)
    {
      let p = readFile(f).then(e => {
        this.emitFile({
          element: e,
          filename: f.name
        });
      });
      promises.push(p);
    }
    Promise.all(promises).then(e => {
      ev.target.value = null;
    })
    this.active = false;
  }
}
