





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { INavbarButton, INavbarImage, INavbarNavigation, NavbarElementModel } from "@/services/NavbarService";

@Component
export default class NavbarElement extends Vue
{
  @Prop()
  model: NavbarElementModel

  get buttonModel() {
    return this.model as INavbarButton;
  }
  get navigationModel() {
    return this.model as INavbarNavigation;
  }
  get imageModel() {
    return this.model as INavbarImage;
  }
}
