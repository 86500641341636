
declare global
{
  interface Array<T> {
    contains(arg: T | ((el: T) => boolean)): boolean;
    any(fn?: (el: T) => boolean): boolean;
    remove(arg: T | ((el: T) => boolean)): void;
    all(fn: (el: T) => boolean): boolean;
    orderBy(fn: (el: T) => any): Array<T>;
    selectMany<TProp>(fn: (el: T) => TProp[]): Array<TProp>;
    distinct<TProp>(fn?: (el: T) => TProp): Array<TProp>
  }
  interface StringConstructor
  {
    isNullOrEmpty(str: string): boolean;
  }
  interface Date {
    addDays(num: number): Date
  }
}

String.isNullOrEmpty = function(str: string)
{
  return str == null || str == "";
}

Date.prototype.addDays = function(this: Date, num: number)
{
  return new Date(this.getTime() + num * 24 * 60 * 60 * 1000);
}

Array.prototype.distinct = function(fn)
{
  let res: any[] = this;
  if(fn != null)
    res = res.map(e => fn(e));
  return res.filter((v,i,a) => a.indexOf(v) == i);
}

Array.prototype.contains = function(arg) {
  if(typeof(arg) == "function")
    return this.find(arg) != null;

  return this.indexOf(arg) >= 0;
};

Array.prototype.orderBy = function(arg) {
  this.sort((a,b) => {
    let _a = arg(a);
    let _b = arg(b);
    return _a == _b ? 0 : (_a < _b ? -1 : 1);
  });
  return this;
};

Array.prototype.remove = function(arg) {
  if(typeof(arg) == "function")
  {
    for(let el of this.filter(arg))
    {
      this.splice(this.indexOf(el), 1);
    }
  }
  else
  {
    this.splice(this.indexOf(arg), 1);
  }
};

Array.prototype.any = function(fn) {
  if(fn != null)
    return this.filter(el => fn(el)).length != 0;
  else
    return this.length != 0;
};

Array.prototype.all = function(fn) {
  for(let el of this)
  {
    if(fn(el) == false)
      return false;
  }
  return true;
};

Array.prototype.selectMany = function(fn) {
  return this
    .map(fn)
    .reduce((a,b) => {
      return a.concat(b);
    }, []);
};

export {}
