import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Fragment from 'vue-fragment'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBarcode, faBuilding, faCamera, faCheck, faChevronDown, faChevronRight, faComment, faCompressArrowsAlt, faCross, faEnvelope, faExclamationCircle, faExclamationTriangle, faExpand, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faFilter, faInfoCircle, faLock, faQuestionCircle, faSave, faSignOutAlt, faSpinner,  faTimes, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false
Vue.component('fa-icon', FontAwesomeIcon);
Vue.use(Fragment.Plugin)

library.add(
  faSpinner,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileVideo,
  faFileImage,

  faEnvelope,
  faLock,
  faBarcode,
  faChevronRight,
  faChevronDown,
  faFilter,
  faCamera,
  faCheck,
  faSave,
  faCompressArrowsAlt,
  faTimes,
  faSignOutAlt,
  faExpand,
  faUpload,
  faTrash,
  faQuestionCircle,
  faComment,
  faExclamationCircle,
  faInfoCircle,

  faExclamationTriangle,
  faCheck,
  faInfoCircle,
  faBuilding
);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
