

























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import ElementView from "@/components/ElementView.vue";
import { IImageFile } from './FileUpload.vue';
import { ResizeService } from '@/services/ResizeService';
import { LightboxService } from '@/services/LightboxService';

@Component({
  components: {
    ElementView
  }
})
export default class PhotoView extends Vue
{
  @Prop()
  element: IImageFile;
  @Prop()
  row: any
  @Prop()
  index: number;
  @Prop()
  src: string;
  @Prop({ default: false })
  disabled: boolean;
  @Prop({ default: true })
  expandable: boolean;
  @Prop({ default: null })
  isExpanded: boolean;

  expanded: boolean = false;

  get isMobileView() {
    return ResizeService.isMobileView;
  }

  @Watch("element")
  rowWatcher()
  {
    this.active = false;
    if(this.element && this.element.element)
      this.element.element.classList.add("photo-img");
  }

  created()
  {
    if(this.element && this.element.element)
      this.element.element.classList.add("photo-img");
    this.expanded = this.isExpanded ?? this.expanded;
  }

  active = false;

  @Emit("remove")
  emitRemove()
  {

  }

  onUnexpandImage(ev)
  {
    if(!ResizeService.isMobileView)
      return;
    this.expanded = false;
    ev.stopPropagation();
  }

  onExpandImage(ev)
  {
    if(!ResizeService.isMobileView)
    {
      let src = this.src ?? (this.element.element as HTMLImageElement).src;
      LightboxService.showLightbox({
        imageSrc: src
      });
    }
    else
    {
      this.expanded = true;
      ev.stopPropagation();
    }
  }

  onRemoveImage()
  {
    if(ResizeService.isMobileView && !this.active)
      return;

    this.$emit("remove", this.row, this.element, this.index);
  }

  onPhotoClick()
  {
    console.log("click")
    if(ResizeService.isMobileView)
      this.active = true;
  }

  onPhotoBlur()
  {
    console.log("blur")
    if(ResizeService.isMobileView)
      this.active = false;
  }
}
