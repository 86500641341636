export interface IDisposable
{
  dispose();
  addTo(subs: IDisposable[]);
}

export interface IObservable<T = any>
{
  __isObservable: true;
  subscribe(fn: (ev: T) => void): IDisposable
  cancel(sub: Subscription)
}

export class Subscription implements IDisposable
{
  constructor(
    private obs: IObservable
  )
  {

  }

  addTo(subs: IDisposable[])
  {
    subs.push(this);
  }

  dispose()
  {
    this.obs.cancel(this);
  }
}

export function isObservable<T = any>(o): o is IObservable<T>
{
  return o && typeof o == "object" && o.__isObservable === true;
}

export class MySubject<T> implements IObservable<T>, IDisposable
{
  __isObservable: true = true;
  protected _subs: [Subscription, ((ev: T) => void)][] = [];
  protected _fin: () => void;

  subscribe(fn: (ev: T) => void): IDisposable
  {
    let sub = new Subscription(this);
    this._subs.push([sub, fn]);
    return sub;
  }

  onComplete(fn: () => void)
  {
    if(this._fin != null)
      throw new Error("A complete handler has already been registered for this subject");
    this._fin = fn;
  }

  emit(v: T)
  {
    this._subs.forEach(s => {
      s[1](v);
    });
  }

  dispose()
  {
    this._subs.forEach(s => s[0].dispose())
    this._subs = [];
    if(this._fin)
      this._fin();
    this._fin = null;
  }

  static fromEvent<K extends keyof WindowEventMap>(object: any, type: K, options?: boolean | EventListenerOptions): MySubject<WindowEventMap[K]>
  {
    let res = new MySubject<WindowEventMap[K]>();
    let fn = (ev) => {
      res.emit(ev as any);
    };
    object.addEventListener(type, fn);
    res.onComplete(() => {
      object.removeEventListener(type, fn);
    });
    return res;
  }

  addTo(subs: IDisposable[]): this
  {
    subs.push(this);
    return this;
  }

  cancel(sub: Subscription)
  {
    this._subs.remove(e => e[0] == sub);
  }

  asObservable(): IObservable<T>
  {
    return this as IObservable<T>;
  }
}

export class MyBehaviorSubject<T> extends MySubject<T> implements IObservable<T>
{
  constructor(private _value: T)
  {
      super();
  }

  subscribe(fn: (ev: T) => void): IDisposable
  {
    let res = super.subscribe(fn);
    fn(this._value);
    return res;
  }

  emit(v: T)
  {
    this._value = v;
    this._subs.forEach(s => {
      s[1](v);
    });
  }
}
