



























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { createGuid } from "@/common";
import { MangelStatus } from '@/types/api';
import MangelStatusIcon from "@/components/MangelStatusIcon.vue";

@Component({
  components: {
    MangelStatusIcon
  }
})
export default class MangelStatusSelect extends Vue
{
  @Prop()
  value: MangelStatus;
  @Prop()
  disabled: boolean;
  @Prop()
  includeNull: string;

  MangelStatus = MangelStatus;

  guid = createGuid()

  @Watch("value")
  onValueChanged(o,n)
  {
    console.log(n);
  }

  @Emit("update:value")
  emitUpdateValue(ev)
  {
  }

  @Emit("changed")
  emitChanged(val: MangelStatusSelect)
  {

  }


  onChange(ev)
  {
    this.emitUpdateValue(ev.target.value);
    this.emitChanged(ev.target.value);
  }
}
