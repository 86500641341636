import { uriToBlob } from "@/common";
import { ILoginModel, IMangelListeSaveRequest, IUploadImageModel, ILoginResponse, IMangelListeResponse, IUploadImageResponse, IMangelListeSaveResponse, IRequestPasswordModel } from "@/types/api";
import { IApiError, ApiError, ApiErrorKind } from '../types/api.error';
import { CookieService } from './CookieService';
import { InfoService } from './InfoService';
import { IRequestPasswordResponse } from '../types/api';
import { EventService } from './EventService';

interface IApiRequestOpts
{
  rethrow?: boolean;
  handleError?: boolean;
  disableLoading?: boolean;
}

function isApiError(err: any): err is IApiError
{
  return typeof err == "object" && err.kind != null;
}

class ApiServiceClass
{

  // private _baseUri = `http://${window.location.hostname}:8088/api`
  // private _baseUri = `https://${window.location.hostname}:7071/api`
  private _baseUri = `https://api.doku.center/api`

  private _logoutAnchor: HTMLAnchorElement
  private _requestCounter = 0;
  private basehost = "doku.center";

  public isTestSystem = false;

  constructor()
  {
    let hostname = location.hostname;
    if(hostname == "localhost")
      hostname = "test.doku.center";

    let host = hostname.split(this.basehost)[0] ?? "";
    if(host.endsWith("."))
      host = host.substring(0, host.length - 1);

    this._baseUri = `https://${host}api.${this.basehost}/api`;
    if(host == "test")
    {
      this.isTestSystem = true;
    }

    this._logoutAnchor = document.createElement("a");
    this._logoutAnchor.href = this.ApiUri + "/logout";
  }

  get ApiUri(): string {
    return this._baseUri;
  }

  public async login(model: ILoginModel): Promise<ILoginResponse>
  {
    CookieService.setCookie("email", model.email);
    CookieService.setCookie("firma", model.firma);
    let res = await this._post<ILoginResponse>("/login", model);
    CookieService.setCookie("tokenExpiration", res.tokenExpiration);
    return res;
  }

  public logout()
  {
    CookieService.setCookie("tokenExpiration", "");
    CookieService.setCookie("firma", "");
    CookieService.setCookie("email", "");
    this._logoutAnchor.click();
  }

  public async requestPassword(model: IRequestPasswordModel): Promise<IRequestPasswordResponse>
  {
    CookieService.setCookie("email", model.email);
    CookieService.setCookie("firma", model.firma);
    let res = await this._post<IRequestPasswordResponse>("/requestPassword", model);
    return res;
  }

  public async getMaengelList(id: string, opts?: IApiRequestOpts): Promise<IMangelListeResponse>
  {
    return await this._get<IMangelListeResponse>(`/getMaengelList?id=${id}`, opts);
  }

  public async uploadImage(img: HTMLImageElement, model: IUploadImageModel, opts?: IApiRequestOpts)
  {
    let formData = new FormData();
    let blob = await uriToBlob(img.src);
    formData.append("file", blob, model.filename);
    formData.append("model", JSON.stringify(model));
    return await this.wrapRequest<IUploadImageResponse>(() => {
      return fetch(this.constructUrl("/uploadImage"), {
        method: "PUT",
        credentials: "include",
        body: formData
      });
    }, opts);
  }

  public async updateList(m: IMangelListeSaveRequest, opts?: IApiRequestOpts)
  {
    return await this._post<IMangelListeSaveResponse>("/updateList", m, opts)
  }

  private constructUrl(path: string)
  {
    let res = this._baseUri + path;
    return res;
  }

  public exceptionHandler(res: Response|Error): ApiError
  {
    let err: ApiError;
    console.error(res);
    if(isApiError(res))
    {
      err = new ApiError(res);
    }
    else if(res instanceof Error)
    {
      if(res.message.indexOf("Failed to fetch") >= 0)
      {
        err = new ApiError({
          reason: `Error during server request. Message: ${res.message}`,
          meta: res,
          kind: ApiErrorKind.NoServerConnection
        });
      }
      else
      {
        err = new ApiError({
          reason: `Error during server request. Message: ${res.message}`,
          meta: res,
          kind: ApiErrorKind.GenericFrontendError
        });
      }
    }
    else
    {
      err = new ApiError({
        reason: `Error during server request.`,
        meta: res,
        kind: ApiErrorKind.GenericFrontendError
      });
    }

    InfoService.addMessage({
      severity: "error",
      title: "Oops, ein Fehler ist aufgetreten",
      body: err.toDetailMessage()
    })

    return err;
  }

  private async wrapRequest<T>(fn: () => Promise<Response>, opts: IApiRequestOpts = null): Promise<T>
  {
    opts = opts ?? {};
    this._requestCounter++;
    let counter = this._requestCounter;
    try
    {
      if(opts.disableLoading !== true)
      {
        EventService.emit({
          kind: "requestStarted",
          counter
        });
      }

      let res = await fn();
      if(!res.ok)
      {
        let kind = ApiErrorKind.GenericInternalError;
        switch(res.status)
        {
          case 404:
            kind =  ApiErrorKind.GenericNotFound
            break;
          case 403:
          case 401:
            kind = ApiErrorKind.GenericNotAllowed;
            break;
        }
        throw new ApiError({
          reason: `Error during server request. Status: ${res.statusText}. Body: ${await res.text()}`,
          meta: {
            status: res.status
          },
          kind
        });
      }

      let json = res.json();
      return json as any as T;
    }
    catch(error)
    {
      if(opts.handleError !== false)
      {
        let err = this.exceptionHandler(error);
        if(opts.rethrow)
          throw err;
        return null;
      }
      else
      {
        throw error;
      }
    }
    finally
    {
      if(opts.disableLoading !== true)
      {
        EventService.emit({
          kind: "requestEnded",
          counter: counter
        });
      }
    }
  }

  private _postDownload(uri: string, data: any)
  {
    let mapForm = document.createElement("form");
    mapForm.style.display = "none";
    mapForm.target = "_blank";
    mapForm.method = "post";
    mapForm.action = `${uri}`;
    let mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = "data";
    mapInput.setAttribute("value", JSON.stringify(data,null,0));
    mapForm.appendChild(mapInput);
    document.body.appendChild(mapForm);
    mapForm.submit();
    document.body.removeChild(mapForm);
  }

  public getDocument(name: string, standortnr: string, tmpKey: string)
  {
    this._postDownload(this.constructUrl(`/getDocument`), {
      blobName: name,
      standortkuerzel: standortnr,
      tmpKey
    });
  }

  private async _post<T>(url: string, body: any, opts?: IApiRequestOpts): Promise<T>
  {
    return await this.wrapRequest<T>(() => {
      return fetch(this.constructUrl(url), {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
    }, opts);
  }

  private async _get<T>(url: string, opts?: IApiRequestOpts): Promise<T>
  {
    return await this.wrapRequest<T>(() => {
      return fetch(this.constructUrl(url), {
        method: "GET",
        credentials: "include" //TODO: check if this is the way to go?
      });
    }, opts)
  }
}


export const ApiService = new ApiServiceClass();
