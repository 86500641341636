

export function getQueryParam(q: string|string[]): string
{
  if(!q)
    return null;
  if(Array.isArray(q))
    return q[0];
  return q;
}


export function debounced(fn: (...args) => void, time: number)
{
  let timeout;
  return () => {
    if(timeout)
    {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => fn(), time);
  }
}

export function isAccessControlStatusError(status: number)
{
  return status == 401 || status == 403;
}

export function createGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export async function uriToBlob(uri: string): Promise<Blob>
{
  let res = await fetch(uri);
  return await res.blob();
}


export function assertNever(never: never)
{
  throw new Error("Should never happen");
}

export function deepClone(o: any)
{
  switch(typeof o)
  {
    case "function":
    case "boolean":
    case "string":
    case "number":
    case 'undefined':
      return o;
    case 'object':
    {
      if(o == null)
      {
        return null;
      }
      else if(Array.isArray(o))
      {
        return o.map(e => deepClone(e));
      }
      else
      {
        return Object.keys(o).reduce((c,v) => {
          c[v] = deepClone(o[v]);
          return c;
        }, {});
      }
    }

  }
}

export function *detectChanges<T>(old: T, n: T, path = "$"): Iterable<string>
{
  let ot = typeof(old);
  let nt = typeof(n);

  if(ot != nt)
  {
    yield path;
    return;
  }

  if(Array.isArray(old))
  {
    if(!Array.isArray(n))
    {
      yield path;
      return;
    }

    let max = Math.max(old.length, n.length);
    for(let i=0; i<max; i++)
    {
      yield *detectChanges(old[i], n[i], `${path}.${i}`);
    }
  }
  else if(ot == "function")
  {
  }
  else if(ot == "object" && old != null)
  {
    for(let k of Object.keys(old))
    {
      yield *detectChanges(old[k], n[k], `${path}.${k}`);
    }
  }
  else
  {
    if(old != n)
    {
      yield path;
    }
  }
}
