































































// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { ApiService } from "@/services/ApiService";
import { CookieService } from "@/services/CookieService";
import { DataService } from "@/services/DataService";
import { DialogService } from "@/services/DialogService";
import { InfoService } from "@/services/InfoService";
import { NavbarService } from "@/services/NavbarService";
import { Component, Vue } from "vue-property-decorator";

const infoTag = "login";

@Component
export default class Login extends Vue
{
  user = "";
  firma = "";
  password = "";
  redirectTo: string;
  state: "idle"|"ac" = "idle";


  get requestedResource() {
    return DataService.login.requestedResourceId;
  }

  constructor()
  {
    super();
    let redirectTo = this.$route.query.redirectTo;
    if(Array.isArray(redirectTo))
      redirectTo = redirectTo[0];

    this.firma = CookieService.cookies.firma;
    this.user = CookieService.cookies.email;

    this.redirectTo = redirectTo ?? DataService.login.redirectTo ?? "/";

    if(this.requestedResource == null)
      this.$router.push("/");
  }

  created()
  {
    NavbarService.resetModel();
  }

  async onKeydown(ev: KeyboardEvent)
  {
    if(ev.key == "Enter")
    {
      await this.loginClicked();
    }
  }

  async loginClicked()
  {
    InfoService.removeMessagesWithTag(infoTag);
    if(String.isNullOrEmpty(this.user) || String.isNullOrEmpty(this.password) || String.isNullOrEmpty(this.firma))
      {
        InfoService.show("warn", "Unvollständige Eingabe", "Bitte geben vervollständigen Sie ihre Eingaben", infoTag, 2000);
        return;
      }
    let res = await ApiService.login({
      email: this.user,
      password: this.password,
      firma: this.firma,
      requestedResource: this.requestedResource
    });

    if(res != null)
      this.$router.push(this.redirectTo);
  }

  requestPwClicked()
  {
    InfoService.removeMessagesWithTag(infoTag);
    DialogService.confirm({
      header: "Passwort anfordern",
      body: "Möchten Sie ein Passwort anfordern? Das Passwort wird Ihnen per E-Mail zugesendet und ist für 3 Monate gültig. Danach können Sie erneut ein Passwort anfordern.",
      mode: "confirm",
      buttons: [
        {
          label: "Passwort anfordern",
          click: async () => {
            if(String.isNullOrEmpty(this.firma) || String.isNullOrEmpty(this.user))
            {
              InfoService.show("warn", "Unvollständige Eingaben", "Bitte füllen Sie das E-Mail und Firma Feld aus um ein Passwort anzufordern", infoTag);
              return;
            }
            let res = await ApiService.requestPassword({
              email: this.user,
              firma: this.firma,
              requestedResource: this.requestedResource
            });
            if(res)
              InfoService.show("success", "Passwort angefordert", "Ihr Zugangspasswort sollte in wenigen Minuten bei Ihnen im Postfach erscheinen", infoTag, 2000);
          }
        },
        {
          label: "Abbrechen",
          click: () => {

          }
        }
      ]
    })
  }
}
