






import { assertNever } from '@/common';
import { MangelStatus } from '@/types/api';
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MangelStatusIcon extends Vue
{
  @Prop()
  status: MangelStatus;

  get className()
  {
    if(!this.status)
      return "";
    return this.status.replace(/\s+/g, "-").toLowerCase();
  }

  get icon(): string
  {
    switch(this.status)
    {
      case MangelStatus.Behoben:
        return "check";
      case MangelStatus.NichtBehoben:
        return "times";
      case MangelStatus.NichtBeauftragt:
        return "times"; //TODO: define
      case MangelStatus.KeinMangel:
        return null;
      case MangelStatus.Undefined:
        return null; //TODO: define
      default:
        return null;
        //assertNever(this.status); TODO warum ist hier leer bei ausgeblendetem select?
    }
  }

  MangelStatus = MangelStatus;
}
