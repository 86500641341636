






















































































































































































































































import { ApiService } from "@/services/ApiService";
import { DataService } from "@/services/DataService";
import { InfoService } from "@/services/InfoService";
import { DialogService } from '@/services/DialogService';
import { ResizeService } from "@/services/ResizeService";
import { EventService } from '@/services/EventService';
import { ColumnSpec, CustomViewService } from "@/services/CustomViewService";
import { Component, Vue } from "vue-property-decorator";
import { detectChanges, getQueryParam, isAccessControlStatusError } from "@/common";
import { IDisposable, MyBehaviorSubject, MySubject } from '@/misc/MySubject';
import { IMangelBild, IMangelListeResponse, IMangelListeSaveRequest, IUploadImageResponse, MangelStatus, IMangelListeSaveResponse } from '@/types/api';
import { IMangelRow, IPropertyViewDefinition, MangelListColumns, MAX_PHOTOS } from "@/constants";
import DistinctSelect from "@/components/DistinctSelect.vue";
import MangelStatusIcon from "@/components/MangelStatusIcon.vue";
import MangelStatusSelect from "@/components/MangelStatusSelect.vue";
import FileUpload, { IImageFile } from "@/components/FileUpload.vue";
import PhotoView from "@/components/PhotoView.vue";
import { IApiError } from "@/types/api.error";
import { NavbarService } from "@/services/NavbarService";
import { pluralize } from '../filters/Pluralize';

const MIN_COMMENT_LENGTH = 20;

type RowState = {
  expanded: boolean;
  editStatusDisabled?: boolean;
  editStatusInvisible?: boolean;
  editCommentDisabled?: boolean;
  editPhotosDisabled?: boolean;
  newStatus: MangelStatus;
  addComment?: string;
  images: IImageFile[];
  mangelPhotosLoaded?: boolean;
  error: {
    kommentar?: string;
    mangelStatus?: string;
  }
}

const infoTag = "MangelListe";

const defaultRowState: Partial<RowState> = {
  expanded: false,
  editStatusDisabled: false,
  editCommentDisabled: false,
  editPhotosDisabled: false,
  mangelPhotosLoaded: false,
  addComment: null,
  images: [],
};

type Row = IMangelRow & { _state: typeof defaultRowState }

@Component({
  components: {
    DistinctSelect,
    MangelStatusSelect,
    FileUpload,
    PhotoView,
    MangelStatusIcon
  },
  filters: {
    pluralize: pluralize
  }
})
export default class MangelListe extends Vue
{
  key = 0;
  mangelListeId: string;
  resClone: IMangelListeResponse = null;
  res: IMangelListeResponse = null;
  rows: Row[] = [];
  filterStatus = null;
  mobileFiltersExpanded = false;

  get isDirty() {
    let changes = Array.from(detectChanges(this.resClone, this.prepareModel()));
    return changes.length > 0;
  }
  get allMangelPhotosLoaded() {
    return this.rows.all(r => r._state.mangelPhotosLoaded === true);
  }
  get noMangelPhotosAvailable() {
    return this.rows.all(r => r.mangelBilder == null || r.mangelBilder.length == 0);
  }

  MangelStatus = MangelStatus;

  resultRows: Row[] = [];
  allCols = MangelListColumns;
  mainCols: typeof MangelListColumns = [];
  subCols: typeof MangelListColumns = [];
  mainColCnt = 0;
  isMobile = ResizeService.isMobileView;
  colSpec: ColumnSpec[];

  filters: Record<string, any> = {};

  subscriptions: IDisposable[] = [];

  isRedirecting = false;

  constructor()
  {
    super();
    // if(CookieService.tokenExpiration < new Date())
    // {
    //   DataService.login.redirectTo = this.$route.fullPath;
    //   this.$router.push("/login");
    //   this.isRedirecting = true;
    // }
    // else
    // {
    //   this.mangelListeId = id;
    // }
  }

  onRowEdited(r: Row)
  {
    r._state.error = {};
    if(r._state.newStatus == MangelStatus.NichtBehoben &&  r.mangelStatus != r._state.newStatus && (r._state.addComment == null  || r._state.addComment.length < MIN_COMMENT_LENGTH))
    {
      r._state.error.kommentar = "Wenn Sie den Status auf Nicht behoben setzen  müssen mindestens 20 Zeichen als Kommentar eingeben."
    }
    r.mangelStatus = r._state.newStatus;
  }

  onDistinctSelectChanged(key: string, value: any)
  {
    if(value == "null")
      value = null;
    this.filters[key] = value;
    this.onFiltered();
  }

  onStatusFilterChanged(val: string)
  {
    this.filters.mangelStatus = val;
    this.onFiltered();
  }

  onExpandClicked(m: Row)
  {
    if(!this.isMobile)
      return;
    m._state.expanded = !m._state.expanded;
  }

  onFile(row: Row, ev: IImageFile)
  {
    let cnt = row._state.images.length + (row.bilder != null ? row.bilder.length : 0);
    if(cnt >= MAX_PHOTOS)
    {
      InfoService.show("warn", "Maximale Photoanzahl erreicht", `Es können pro Mangel maximal ${MAX_PHOTOS} Photos verwendet werden, daher wurden nicht alle ausgewählten photos übernommen.`, infoTag)
    }
    else
    {
      row._state.images.push(ev);
      cnt++;
    }

    if(cnt >= MAX_PHOTOS)
      row._state.editPhotosDisabled = true;
  }

  onPhotoRemove(row: Row, el: IImageFile, i: number)
  {
    let idx = row._state.images.indexOf(el);
    if(idx < 0)
    {
      console.error("Could not find photo element");
      return;
    }
    row._state.images.splice(idx, 1);
    let cnt = row._state.images.length + (row.bilder != null ? row.bilder.length : 0);
     if(cnt < MAX_PHOTOS)
      row._state.editPhotosDisabled = false;
  }

  onFiltered()
  {
    let res = this.rows.slice();
    for(let c of this.allCols.filter(c => c.filter != null))
    {
      let v = this.filters[c.key];
      if(v != null && v != "" && v != "__null__")
      {
        if(c.filter == "distinct")
        {
          res = res.filter(l => l[c.key] == v);
        }
      }
    }
    if(!String.isNullOrEmpty(this.filters.mangelStatus))
    {
      res = res.filter(l => l.mangelStatus == this.filters.mangelStatus);
    }
    this.resultRows = res;
  }

  onResized()
  {
    if(ResizeService.isMobileView)
    {
      this.mainCols = MangelListColumns.slice().filter(e => e.isMobileMainCol === true && !this.colSpec.any(s => s.key == e.key && s.isHidden == true));
      this.subCols = MangelListColumns.slice().filter(e => e.isMobileMainCol !== true && !this.colSpec.any(s => s.key == e.key && s.isHidden == true));
      this.mainColCnt = this.mainCols.length + 3;
    }
    else
    {
      this.mainCols = MangelListColumns.slice().filter(e =>
         e.isMobileOnly !== true && !this.colSpec.any(s => s.key == e.key && s.isHidden == true));
      this.mainColCnt = this.mainCols.length + 1;
    }
    this.rows.forEach(e => e._state.expanded = false);
    this.isMobile = ResizeService.isMobileView;
    this.orderCols();
  }

  onBeforeUnload(ev)
  {
    if(this.isDirty === true)
    {
      ev.returnValue = "Es liegen ungespeicherte Änderungen vor. Wollen Sie die Seite trotzdem verlassen?"
    }
  }

  orderCols()
  {
    this.mainCols = this.mainCols.orderBy(m => m.order ?? -1);
    this.subCols = this.subCols.orderBy(m => m.order ?? -1);
  }

  async created()
  {
    window.addEventListener("beforeunload", this.onBeforeUnload);

    let res: IMangelListeResponse;
    if(DataService.mangelliste.model != null)
    {
      res = DataService.mangelliste.model;
      this.mangelListeId = DataService.mangelliste.mangelListeId;
      this.colSpec = CustomViewService.getColumnSpec(this.mangelListeId)
    }
    else
    {
      try
      {
        this.mangelListeId = getQueryParam(this.$route.params.id);
        if(!this.mangelListeId)
        {
          this.$router.push("/");
          return;
        }
        this.colSpec = CustomViewService.getColumnSpec(this.mangelListeId)
        res = await ApiService.getMaengelList(this.mangelListeId, { handleError: false });
      }
      catch(err)
      {
        let e = err as IApiError;
        if(e.meta && e.meta.status)
        {
          let status = e.meta.status;
          if(isAccessControlStatusError(status))
          {
            DataService.login.redirectTo = "/mangelliste/" + this.mangelListeId;
            DataService.login.requestedResourceId = this.mangelListeId;
            this.$router.push("/login");
            return;
          }
          else
          {
            ApiService.exceptionHandler(err);
            this.$router.push(`/${this.mangelListeId}`);
            return;
          }
        }
        else
        {
          ApiService.exceptionHandler(err);
          this.$router.push(`/${this.mangelListeId}`);
          return;
        }
      }
    }

    NavbarService.setModel({
      brand: {
        class: "lidl-bg",
        elements: [
          {
            kind: "image",
            logo: "/img/lidl.svg",
            title: "Mängelverwaltung"
          },
          {
            kind: "button",
            label: "Speichern",
            action: () => {
              this.onSave()
            },
            icon: "save",
            visible: isMobile => isMobile
          }
        ]
      },
      start: {
        class: "lidl-bg flex-grow",
        elements: [
          {
            kind: "button",
            label: "Speichern",
            action: () => {
              this.onSave()
            },
            icon: "save",
            visible: isMobile => !isMobile
          }
        ]
      },
      end: {
        elements: [
          {
            kind: "navigation",
            href: "/mangelliste/hilfe",
            label: "Hilfe",
            icon: "question-circle",
            target: "_blank"
          },
          ...NavbarService.defaultModel.end.elements.filter(e => !(e.kind == "navigation" && e.id == "help"))
        ]
      }
    })

    this.setModel(res);
    this.onResized();

    ResizeService.sub
      .subscribe(() => {
        this.onResized();
      })
      .addTo(this.subscriptions);

    EventService
      .subscribe("saveClicked", (ev) => {
        this.onSave();
      })
      .addTo(this.subscriptions);
  }

  onMangelPhotosLoadClicked(l: Row)
  {
    if(l._state.mangelPhotosLoaded !== true)
      l._state.mangelPhotosLoaded = true;
  }

  onLoadAllMangelPhotosClicked()
  {
    this.rows.forEach(r => this.onMangelPhotosLoadClicked(r));
  }

  setModel(res: IMangelListeResponse)
  {
    this.resClone = JSON.parse(JSON.stringify(res));
    this.res = res;
    this.rows = [];
    this.rows = res.maengel.map(r => {
      return {
        ...r,
        kurzbeschreibung: r.beschreibung.substring(0, 50) + "...",
        _state: (r as Row)._state ?? {
          ...defaultRowState,
          error: {},
          images: [],
          editStatusDisabled: r.mangelStatus == MangelStatus.Behoben,
          editStatusInvisible: r.mangelStatus == MangelStatus.KeinMangel,
          editCommentDisabled: false,
          editPhotosDisabled: false,
          newStatus: r.mangelStatus
        }
      };
    });
    // this.key++;
    this.onFiltered();
  }

  getColumnName(colProp: IPropertyViewDefinition<keyof IMangelRow>): string
  {
    return this.colSpec.find(s => s.key == colProp.key)?.displayName ?? colProp.displayName;
  }

  prepareModel(): IMangelListeSaveRequest["payload"]
  {
    return {
      location: this.res.location,
      maengel: this.rows.map(r => {

        let kommentar = r.kommentar;
        if(!String.isNullOrEmpty(r._state.addComment))
        {
          if(String.isNullOrEmpty(kommentar))
            kommentar = "";
          else
            kommentar = kommentar + "\n";
          kommentar += r._state.addComment
        }

        let res = {
          ...r,
          kommentar: kommentar,
          status: r._state.newStatus,
          bilder: [
            ...(r.bilder ?? []),
            ...r._state.images.map(i => {
              let res: IMangelBild = {
                id: i.id,
                bezeichnung: null, //TODO:
                url: null
              }
              return res;
            })
          ],
          _state: null
        }

        delete res.kurzbeschreibung;
        delete res._state;

        return res;
      })
    };
  }

  async save(mode: IMangelListeSaveRequest["mode"])
  {
    let ret;
    try
    {
      InfoService.clearMessages();

      let iter = 1;

      let imagesToUpload = this.rows
        .selectMany(r => r._state.images
          .filter(i => i.id == null)
          .map(i => {
            return {
              i: i,
              r: r
            }
          })
        ); //id != null have already been uploaded

      let count = imagesToUpload.length;

      if(imagesToUpload.length > 0)
      {
        for(let { r, i } of imagesToUpload)
        {
          InfoService.removeMessage(ret)

          ret = InfoService.addMessage({
            severity: "busy",
            tags: infoTag,
            title: "Lade Bilder hoch",
            body: `Bild ${iter}/${count}`,
            closable: false
          });

          let res = await ApiService.uploadImage(i.element as HTMLImageElement, {
            mangelId: r.id,
            reportId: r.reportId,
            filename: i.filename
          }, { handleError: false });
          i.id = res.id;
          iter++;
        }
      }

      //TODO: Prepare model
      let model: IMangelListeSaveRequest["payload"] = this.prepareModel();

      if(mode == "Commit")
      {
        let now = new Date().toISOString();
        model.maengel
          .filter(e => e.abgeschlossenAt == null && e.mangelStatus != MangelStatus.Undefined)
          .forEach(e => e.abgeschlossenAt = now);
      }

      InfoService.removeMessage(ret);
      ret = InfoService.addMessage({
        severity: "busy",
        tags: infoTag,
        title: "Speichere",
        body: `Speichere Mangelliste`,
        closable: false
      });

      let res = await ApiService.updateList({
        mode,
        payload: model
      }, { handleError: false });

      this.setModel(res.newModel);
      let msg = "Die Mangelliste wurde erfolgreich gespeichert."
      if(mode == "Commit") {
        msg = "Die Mangelliste wurde erfolgreich gespeichert. Sie erhalten innerhalb eines Arbeitstags eine E-Mail zur Bestätgigung."
      }
      InfoService.show("success", "Gespeichert", msg, infoTag);
    }
    catch(err)
    {
      let e = err as IApiError;
      if(e.meta && e.meta.status && isAccessControlStatusError(e.meta.status))
      {
        InfoService.show("error", "Session abgelaufen", "Ihre Benutzersession ist abgelaufen. Bitte loggen Sie sich erneut ein (ihre Änderungen bleiben erhalten).");
        DataService.mangelliste.model = {
          ...this.res,
          maengel: this.rows
        };
        DataService.login.redirectTo = this.$route.fullPath;
        DataService.login.requestedResourceId = this.mangelListeId;
        this.$router.push("/login")
        return;
      }
      else
      {
        ApiService.exceptionHandler(err);
      }
    }
    finally
    {
      if(ret != null)
        InfoService.removeMessage(ret);
    }
  }

  onSave()
  {
    DialogService.confirm({
      header: "Speichern",
      body: "Möchten Sie die Änderungen nur zwischenspeichern oder auch an Lidl melden?",
      footer: `Beim Speichern werden Ihre Bilder auf den Server übertragen. Dies kann je nach Anzahl und Größe der Bilder einige Momente dauern. Wir empfehlen Ihnen die Übertragung über eine WLAN-Verbindung.`,
      mode: "confirm",
      buttons: [
        {
          label: "Änderungen melden",
          click: async () => {
            if(this.rows.any(r => r._state.error.kommentar != null || r._state.error.mangelStatus != null))
            {
              InfoService.show("warn", "Ungültige Eingaben", "Es liegen ungültige Eingaben vor. Bitte prüfen Sie alle Eingaben.", infoTag)
              return;
            }
            await this.save("Commit");
          }
        },
        {
          label: "Zwischenspeichern",
          click: async () => {
            await this.save("Save");
          }
        },
        {
          label: "Abbrechen",
          click: () => {

          }
        }
      ]
    });
  }

  destroyed()
  {
    window.removeEventListener("beforeunload", this.onBeforeUnload);

    this.subscriptions.forEach(s => s.dispose());
    this.subscriptions = [];
  }

}
