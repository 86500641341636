<template>
  <div class="app-root">
    <toast-container></toast-container>
    <navbar></navbar>
    <loading-bar></loading-bar>
    <div class="app-body">
      <router-view/>
    </div>
    <!-- <my-footer></my-footer> -->
    <dialog-container></dialog-container>
    <lightbox-container></lightbox-container>
  </div>
</template>

<script>

import "./misc/extensions";
import { Component, Vue } from "vue-property-decorator"
import Navbar from "@/components/Navbar"
import MyFooter from "@/components/MyFooter"
import DistinctSelect from '@/components/DistinctSelect.vue';
import ToastContainer from '@/components/ToastContainer.vue';
import DialogContainer from '@/components/DialogContainer.vue';
import LightboxContainer from './components/LightboxContainer.vue';
import LoadingBar from './components/LoadingBar.vue';
import { InfoService } from "./services/InfoService";

@Component({
  components: {
    Navbar,
    MyFooter,
    DistinctSelect,
    ToastContainer,
    DialogContainer,
    LightboxContainer,
    LoadingBar
  }
})
export default class App extends Vue
{
  isOldBrowser = window.fetch == null || window.Promise == null || window.Symbol == null;
  // isOldBrowser = true

  constructor()
  {
    super();


  }

  created()
  {
    document.querySelector("html").style.overflow = "hidden";

    let agent = navigator.userAgent;
    let raw = agent.match(/Chrom(e|ium)\/([0-9]+)\./);
    let rawFF = agent.match(/Firefox\/([0-9]+)\./);
    let chromeVersion = raw ? parseInt(raw[2], 10) : 0;
    let firefoxVersion = rawFF ? parseInt(rawFF[1], 10) : 0;
    if(!(chromeVersion > 41 || firefoxVersion > 71))
      this.isOldBrowser = true;

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;

    if(isSafari) {
      setTimeout(() => {
        InfoService.addMessage({
          severity: "warn",
          title: "Nicht unterstützter Web-Browser",
          body: "iOS Betriebssysteme werden derzeit nicht unterstützt."
        });
      });
    } else if(this.isOldBrowser == true)
    {
      setTimeout(() => {
        InfoService.addMessage({
          severity: "warn",
          title: "Alter oder nicht erkannter Web-Browser",
          body: "Sie verwenden keinen modernen Web-Browser. Eventuell funktioniert die Website nicht korrekt. Verwenden Sie bitte einen modernen, aktuellen Browser (Chrome, Edge oder Firefox)"
        });
      });
    }
  }
}

</script>

<style lang="sass">
@import "./style/variables"

html
  overflow: auto

.app-root
  height: 100vh
  position: relative
  .app-body
    height: $bodyHeight

@import "./style/global"

</style>
