import { IDataResponse } from '../misc/interfaces';
import { IMangelListeResponse } from '../types/api';

class DataServiceClass
{
  login = {
    redirectTo: null,
    requestedResourceId: null
  }
  mangelliste: { model: IMangelListeResponse; mangelListeId: string } = {
    model: null,
    mangelListeId: null
  }
  dataResponse: IDataResponse;
}

export const DataService = new DataServiceClass();
