





















































// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Page extends Vue
{
}
