export interface ColumnSpec
{
  key: string;
  isHidden: boolean;
  displayName: string;
}

class CustomViewServiceClass
{
  private _columnSpecDicts: { [key: string]: ColumnSpec[] } = {
    "ML": [    ],
    "KB": [
      { key: "beschreibung", displayName: "Handlungsanweisung", isHidden: false },
      { key: "anmerkungLidl", displayName: "", isHidden: true },
      { key: "prio", displayName: "", isHidden: true },
      { key: "art", displayName: "", isHidden: true },
    ],
    "<default>": [ ]
  };

  getColumnSpec(mangelListeId: string): ColumnSpec[]
  {
    let id = mangelListeId;
    let listType = id.substring(id.length - 3, id.length - 1); //"<unique(6-10)><listType(2)><checksum(1)>"
    if(id == null || this._columnSpecDicts[listType] == null)
      return this._columnSpecDicts["<default>"];
    return  this._columnSpecDicts[listType];
  }
}

export const CustomViewService = new CustomViewServiceClass();
