























// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { getQueryParam, isAccessControlStatusError } from "@/common";
import { ApiService } from "@/services/ApiService";
import { CustomViewService } from "@/services/CustomViewService";
import { DataService } from "@/services/DataService";
import { InfoService } from "@/services/InfoService";
import { NavbarService } from "@/services/NavbarService";
import { IApiError } from "@/types/api.error";
import { Component, Vue } from "vue-property-decorator";

const infoTag = "barcode";

@Component
export default class Barcode extends Vue
{
  code = "";
  redirectTo: string;

  constructor()
  {
    super();

    if(this.$route.params.code)
    {
      this.code = getQueryParam(this.$route.params.code);
    }
  }

  async created()
  {
    NavbarService.resetModel();
    if(this.code != "")
      this.sendClicked();
  }

  async onKeydown(ev: KeyboardEvent)
  {
    if(ev.key == "Enter")
    {
      await this.sendClicked();
    }
  }

  async sendClicked()
  {
    InfoService.removeMessagesWithTag(infoTag);
    try
    {
      if(String.isNullOrEmpty(this.code))
      {
        InfoService.show("warn", "Unvollständige Eingabe", "Bitte geben Sie einen Barcode ein", infoTag, 2000);
        return;
      }
      let res = await ApiService.getMaengelList(this.code, { handleError: false });
      DataService.mangelliste.model = res;
      DataService.mangelliste.mangelListeId = this.code;
      this.$router.push(`/mangelliste/${this.code}`);
    }
    catch(err)
    {
      let e = err as IApiError;
      if(e.meta && e.meta.status)
      {
        let status = e.meta.status;
        if(isAccessControlStatusError(status))
        {
          DataService.login.redirectTo = "/" + this.code;
          DataService.login.requestedResourceId = this.code;
          this.$router.push("/login");
        }
        else
        {
          ApiService.exceptionHandler(err);
        }
      }
      else
      {
        ApiService.exceptionHandler(err);
      }
    }
  }
}
